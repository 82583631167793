.blog {
  &-content {}
  &-list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @include bpSp {
      display: block;
    }

    &::before {
      content: "";
      display: block;
      max-width: 300px;
      width: 100%;
      order: 1;
      height: 0;
      @include bpSp {
        width: 80%;
      }
    }

    &-item {
      max-width: 300px;
      width: 100%;
      margin-bottom: 35px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      @include bpSp {
        width: 80%;
        margin-right: auto;
        margin-left: auto;
      }
      & + & {
        @include bpSp {
          margin-top: 20px;
          margin-right: auto;
          margin-left: auto;
        }
      }
    }
    &-image {
      margin-bottom: 10px;
    }
    &-link {
      @include f-size(18);
      @include tracking(60);
      font-weight: 500;
    }
    &-date {
      display: block;
      margin-right: 10px;
      font-family: $Josefin;
      @include tracking(340);
      font-weight: 300;
    }
  }
}