@charset "UTF-8";

/*
#styleguide
.m-normalBtn

汎用ボタンのクラス
aタグ、buttonタグ、input[type=submit]、input[type=button]に対応
```
<div class="mB20">
  <a href="/" class="m-normalBtn m-normalBtn-square m-normalBtn-c-black m-normalBtn-middle m-normalBtn-white">
    お問い合わせ
  </a>
</div>
<div class="mB20">
  <a href="/" class="m-normalBtn m-normalBtn-square m-normalBtn-middle">
    お問い合わせ
  </a>
</div>
```
*/
.m-normalBtn {
  display: inline-block;
  max-width: 305px;
  width: 100%;
  padding: 15px;
  background-color: $color5;
  border: 2px solid $color5;
  border-radius: 25px;
  transition: all .2s ease;
  font-family: $Muli;
  @include f-size(14);
  line-height: 1;
  text-align: center;
  color: #ffffff;

  @include bpTb {
    width: 100%;
    max-width: 500px;
  }

  @include bpSp {
    width: 80%;
  }

  &:hover {
    background-color: #fff;
    color: $color5;
    text-decoration: none;
  }

  @at-root {
    input[type=submit].m-btn1,
    input[type=button].m-btn1 {
      border: 2px solid $color5;
      border-radius: 3px;
    }
  }

  &-middle {
    width: 150px;
  }

  &-large {
    width: 255px;
  }

  &-max {
    width: 100%;
  }

  &-small {
    width: auto;
    min-width: 90px;
  }

  &-square {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &-white {
    background-color: #ffffff;
  }

  &-c {
    &-black {
      color: #000000;
      &:hover {
        background-color: $color1;
        color: #ffffff;
        text-decoration: none;
      }
    }
  }
}


.m-view_btn {
  &-center {
    text-align: center;
  }
}


.btn-link_circle {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;

  .link__arrow {
    position: absolute;
    top: 50%;
    right: 8%;
    display: block;
    transform: translateY(-50%);
    @include bpTb {
      right: 4%;
    }

    span {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate3d(-50%, -50%,0);
      display: block;
      width: 57px;
      height: 18px;
      overflow: hidden;
      transition: opacity .5s .7s, transform .5s .7s, -webkit-transform .5s .7s;

      &::before,
      &::after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        background-image: url(../img/common/circle_arrow.svg);
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
        transition: transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 1s cubic-bezier(0.785, 0.135, 0.15, 0.86);
      }
      &::before {
        right: 0;
      }

      &::after {
        right: 200%;
      }
    }
  }

  .link__arrow__pc,
  .link__arrow__sp {
    circle {
      fill: transparent;
      stroke: rgba(129,121,98,1);
      stroke-width: 1;
      stroke-dasharray: 980 980;
      transition: stroke-dasharray 1s .2s;
    }
  }

  .link__arrow__pc {
    @include bpTb {
      display: none;
    }
  }

  .link__arrow__sp {
    display: none;
    @include bpTb {
      display: block;
    }
  }

  &:hover {
    .link__arrow__pc,
    .link__arrow__sp {
      circle {
        stroke-dasharray: 0 980;
        transition: stroke-dasharray .8s;
      }
    }

    .link__arrow {
      span {
        &::before,
        &::after {
          transform: translate3d(200%, 0, 0);
          transition: transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86), -webkit-transform 0.5s cubic-bezier(0.785, 0.135, 0.15, 0.86);
        }
      }
    }
  }
}



/*
#styleguide
.m-arrowBtn

汎用ボタンのクラス
aタグ、buttonタグ、input[type=submit]、input[type=button]に対応
```
<div class="mB20">
  <a href="/" class="m-arrowBtn m-arrowBtn-middle m-arrowBtn-right m-arrowBtn-white m-arrowBtn-borderWhite">
    VIEW MORE
  </a>
</div>

<div class="mB20">
  <a href="/" class="m-arrowBtn m-arrowBtn-middle m-arrowBtn-left">
    VIEW MORE
  </a>
</div>
```
*/
.m-arrowBtn {
  display: block;
  @include f-size(15);
  color: #000000;
  @include bpSp {
    @include f-size(14);
  }
  &:hover {
    text-decoration: none;
  }

  &-inner {
    position: relative;
    display: block;
    width: 100%;
    border-bottom: 2px solid #000000;
    padding-bottom: 10px;
    color: #000000;
    @include bpSp {
      padding-bottom: 5px;
    }
    &::after {
      color: $color1;
    }
  }

  &-center {}
  &-right {
    position: relative;
    text-align: left;
    .m-arrowBtn-inner {
      padding-left: 10px;
      &::after {
        content: "\f061";
        display: block;
        position: absolute;
        top: 18%;
        right: 10px;
        @include iconFont();
        transition: all linear .2s;
      }

    }
    &:hover {
      .m-arrowBtn-inner {
        &::after {
          right: 5px;
        }
      }
      &.m-arrowBtn-inner {
        &::after {
          right: 5px;
        }
      }
    }

    &.m-arrowBtn-inner {
      &::after {
        content: "\f061";
        display: block;
        position: absolute;
        top: 18%;
        right: 10px;
        @include iconFont();
        transition: all linear .2s;
      }
    }
  }
  &-left {
    position: relative;
    text-align: right;
    .m-arrowBtn-inner {
      padding-right: 10px;
      &::after {
        content: "\f060";
        display: block;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        @include iconFont();
        transition: all linear .2s;
      }
    }

    &:hover {
      .m-arrowBtn-inner {
        &::after {
          left: 5px;
        }
      }

      &.m-arrowBtn-inner {
        &::after {
          left: 5px;
        }
      }
    }

    &.m-arrowBtn-inner {
      &::after {
        content: "\f060";
        display: block;
        position: absolute;
        top: 50%;
        left: 10px;
        transform: translateY(-50%);
        @include iconFont();
        transition: all linear .2s;
      }
    }
  }

  &-middle {
    width: 170px;
  }

  &-large {
    width: 255px;
  }

  &-white {
    color: #ffffff;
    &::after {
      color: #ffffff;
    }
  }

  &-borderWhite {
    border-bottom: 2px solid #ffffff;
  }
}


/*
#styleguide
.m-btn1-wrap

.m-btn1を横並びにするクラス
```
<div class="m-btn1-wrap">
  <a href="#" class="m-btn1 m-btn1-arrow-right">流れ</a>
  <a href="#" class="m-btn1 m-btn1-arrow-right">特徴</a>
</div>
```
*/
.m-btn1-wrap {
  text-align: center;
}

/*
#styleguide
.m-btn-text

テキストリンク
```
<a href="#" class="m-btn-text">もっとみる</a>
```
*/
.m-btn-text {
  color: $color1;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}


.m-btn-wrap {
  &-center {
    display: flex;
    justify-content: center;
  }
}