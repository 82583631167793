@charset "UTF-8";

/*
#styleguide
.m-title1
タイトルのクラス
```
<h2 class="m-title1">
  <span class="m-title1-ja">特徴</span>
  <span class="m-title1-en">Feature</span>
</h2>
```
*/
.m-heading {
  &-title {
    font-family: $Play;
    // @include fontsize(55, 16);
    @include f-size(55);
    @include tracking(25);
    text-align: center;
    text-transform: uppercase;
    line-height: 1.4;
    @include bpSp {
      @include f-size(40);
    }
  }
}

.line-title {
  &-text {
    position: relative;
    display: inline-block;
    color: $color1;
    @include fontsize(14,16);
    @include tracking(50);
    font-weight: bold;
    &::after {
      content: "";
      position: absolute;
      top: 50%;
      right: -70px;
      width: 55px;
      height: 1px;
      background-color: $color1;
    }
  }
}


// 下層メインビジュアル
.mv {
  &-content {
    padding: 110px 20px;
    background-color: #e1d3a2;
    @include bpTb {
      padding-top: 60px;
      padding-bottom: 60px;
    }
    @include bpSp {
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }
  &-title {
    font-family: $Play;
    @include f-size(55);
    @include tracking(50);
    text-transform: uppercase;
    text-align: center;
    line-height: 1.5;
    @include bpSp {
      @include f-size(40);
    }
    &_sub {
      display: block;
      text-align: center;
      line-height: 1.3;
      @include f-size(30);
      @include tracking(50);
    }
  }
}