@charset "UTF-8";
/*******************************
Default Mixin
*******************************/
/*----------------------
フォントサイズ「rem」の使用
----------------------*/
@mixin f-size($size: $baseFont) {
  font-size: $size + px;
  font-size: ($size / 10) + rem;
}

/*******************************
Customized Mixin
*******************************/
@mixin wrapper($width: $baseWidth) {
  max-width: $width;
  margin-left: auto;
  margin-right: auto;
  @include breakpoint($bpPcs) {
    max-width: $baseWidthSp;
  }
}

@mixin breakpoint($width: $bpSp) {
  @media screen and (max-width: $width) {
    @content;
  }
}

@mixin bpPcs {
  @media screen and (max-width: $bpPcs) {
    @content;
  }
}

@mixin bpTb {
  @media screen and (max-width: $bpTb) {
    @content;
  }
}

@mixin bpSp {
  @media screen and (max-width: $bpSp) {
    @content;
  }
}

@mixin bpSps {
  @media screen and (max-width: $bpSps) {
    @content;
  }
}

@mixin iconFont() {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  line-height: 1;
}

@mixin fontsize($fontsize, $lin) {
  line-height: $lin / $fontsize;
  font-size: $fontsize + px;
  font-size: #{$fontsize / 10}rem;
}

@mixin tracking($tracking) {
  letter-spacing: ($tracking / 1000 ) + em;
}