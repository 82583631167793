@charset "UTF-8";

/*
#overview
layout
*/

$menuSize: 175px;
$menuSizeMin: 105px;

$menuSizeTb: 120px;
$menuSizeMinTb: 85px;

$menuSizeSp: 90px;
$menuSizeMinSp: 70px;


.l-header {
  position: relative;
  padding-top: 25px;
  padding-bottom: 25px;
  @include bpTb {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 110px;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #fff;
    z-index: 100;
  }
  @include bpSp {
    height: 80px;
  }
  &__button {
    display: none;
    @include bpTb {
      position: absolute;
      top: 30px;
      left: $baseWidthPadding;
      display: block;
      width: 5%;
      height: calc( 50% - 20px);
    }
    @include bpSp {
      width: 10%;
      height: calc(60% - 20px);
    }
    &::before,
    &::after {
      content: "";
      position: absolute;
      left: 0;
      display: block;
      width: 100%;
      height: 2px;
      background-color: $color1;
    }
    &::before {
      top: 0;
    }
    &::after {
      bottom: 0;
    }
    &-border {
      display: block;
      position: absolute;
      top: calc(50% - 1px);
      left: 0;
      width: 100%;
      height: 2px;
      background-color: $color1;
    }
  }
  &__logo-sp {
    display: none;
    @include bpTb {
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      display: block;
      height: calc(100% - 20px);
    }
    &-image {
      @include bpTb {
        display: block;
        height: 100%;
      }
      img {
        width: 107px;
        height: 100%;
        @include bpTb {
          width: auto;
        }
      }
    }
  }
  &__nav {
    @include bpTb {
      position: fixed;
      top: 110px;
      left: 0;
      display: none;
      width: 100%;
      // height: calc(90% - 80px);
      background-color: #fff;
      overflow-y: scroll;
      padding-top: 20px;
      padding-right: $baseWidthPadding;
      padding-bottom: 30px;
      padding-left: $baseWidthPadding;
      @include bpTb {
        border-top: 1px solid $color1;
      }
      @include bpSp {
        top: 80px;
      }
    }
    &.is-open {
      display: block;
    }
    &-list {
      display: flex;
      justify-content: center;
      align-items: center;
      @include bpTb {
        flex-direction: column;
      }
    }
    &-item {
      @include bpTb {
        width: 100%;
        text-align: center;
      }
      & + & {
        margin-left: 60px;
        @include bpTb {
          margin-left: 0;
          border-top: 1px solid $color1;
        }
      }
      &_logo {
        @include bpTb {
          display: none;
        }
        &-image {
          img {
            width: 107px;
            height: 100%;
          }
        }
      }
    }
    &-link {
      display: block;
      padding: 5px;
      font-family: $Muli;
      text-transform: uppercase;
      @include bpTb {
        padding-top: 15px;
        padding-bottom: 15px;
      }
      &:hover {
        text-decoration: none;
      }
    }
  }

  &__online {
    position: absolute;
    top: 45px;
    right: 45px;
    @include bpTb {
      right: $baseWidthPadding;
      top: 12px;
      height: calc(90% - 20px);
    }
    &-link {
      @include bpTb {
        display: block;
        height: 100%;
      }
      img {
        @include bpTb {
          width: auto;
          height: 100%;
        }
      }
    }
  }
}



.l-main {
  overflow: hidden;
  @include bpTb {
    margin-top: 80px;
  }
}



.l-footer {
  padding-top: 45px;
  padding-bottom: 30px;
  border-top: 1px solid $color1;
  &__content {}
  &__name {
    text-align: center;
    @include f-size(13);
    font-weight: 500;
  }
  &__addr {
    text-align: center;
    @include f-size(11);
    font-weight: 500;
  }
  &__copy {
    margin-top: 17px;
    @include f-size(10);
    text-align: center;
  }
}
.pageTop-wrapper {
  position: relative;
  cursor: pointer;
  #pageTop {
    display: block !important;
    position: absolute;
    right: 20px;
    bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: $Muli;
    color: $color1;
    @include f-size(10);
    transform: rotate(-90deg);
    letter-spacing: 0.02em;
    @include bpTb {
      right: 5px;
    }
  }
}