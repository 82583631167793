@charset "UTF-8";

/*
#styleguide
.m-wrapper

中央寄せのクラス
```
<div class="m-wrapper">
デフォルトmax-width: 1000px;
</div>
```
*/
.m-wrapper {
  @include wrapper;
}

.m-wrapper-large {
  @include wrapper(1540px);
}

/*
#styleguide
.m-telLink

電話番号のリンク
768pxからクリック（タップ）可能に
```
<a href="tel:" class="m-telLink">電話番号</a>
```
*/
.m-telLink {
  pointer-events: none;
  @include bpTb {
    pointer-events: auto;
    text-decoration: underline;
  }
}

// フッター上部のお問い合わせ
.contact {
  &-wrap {
    display: flex;
    justify-content: space-between;
    text-align: center;
    @include bpTb {
      display: block;
    }
  }
  &-item {
    display: flex;
    justify-content: center;
    width: 50%;
    padding-top: 75px;
    padding-bottom: 75px;
    @include bpTb {
      width: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include bpSp {
      padding-top: 35px;
      padding-bottom: 35px;
    }
    &-tel {
      &-heading {
        margin-bottom: 15px;
        color: #ffffff;
        @include fontsize(24,45);
        font-weight: 500;
        @include bpSp {
          @include fontsize(18,35);
        }
      }
      &-text {
        margin-bottom: 12px;
        color: #ffffff;
        @include fontsize(18,45);
        @include bpSp {
          @include fontsize(16,35);
        }
      }
      &-phone {
        color: #ffffff;
        @include f-size(33);
        font-weight: bold;
        line-height: 1;
        .min-text {
          margin-right: 15px;
          color: #ffffff;
          @include f-size(26);
          line-height: 1.6;
        }
        .m-telLink {
          color: #ffffff;
        }
      }
      &-time {
        color: #ffffff;
        @include fontsize(14,17.5);
      }
    }

    &-form {
      &-heading {
        margin-bottom: 18px;
        color: #ffffff;
        @include f-size(35);
        @include tracking(50);
        font-weight: bold;
        line-height: 1.3;
        @include bpSp {
          margin-bottom: 8px;
          @include f-size(30);
        }
      }
      &-text {
        margin-bottom: 20px;
        color: #ffffff;
        @include f-size(18);
        line-height: 1;
      }
      &-btn {
        &-link {
          display: block;
          max-width: 255px;
          width: 100%;
          margin-right: auto;
          margin-left: auto;
          padding: 18px;
          border: 1px solid $color1;
          background-color: #ffffff;
          color: $color1;
          @include f-size(15);
          transition: .2s ease all;
          &:hover {
            text-decoration: none;
            border: 1px solid #ffffff;
            background-color: $color1;
            color: #ffffff;
          }
        }
      }
    }
  }
  &-bg_blue {
    background-color: $color5;
  }
  &-bg_sky {
    background-color: $color1;
  }
}

// square-wrap
.square-wrap {
  position: relative;
  &::before,
  &::after {
    width: calc(100%);
    height: 0;
    display: block;
    position: absolute;
    left: 0;
    content: "";
  }
  &::before {
    border-bottom: solid 60px #ffffff;
    border-left: solid 60px transparent;
    bottom: 100%;
  }
  &::after {
    border-top: solid 60px #ffffff;
    border-right: solid 60px transparent;
    top: 100%;
  }
}


// パンクズリスト
.breadcrumb {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  @include wrapper();
  &-item {
    font-family: $Muli;
    @include f-size(13);
    & + & {
      &::before {
        content: ">";
        margin-right: 5px;
        margin-left: 10px;
      }
    }
  }
  &-link {
    &:hover {
      text-decoration: underline;
    }
  }
}



// ページナビ
.wp-pagenavi {
  display: flex;
  justify-content: center;
  margin-top: 60px;
  .previouspostslink,
  .nextpostslink {
    display: block;
    max-width: 305px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 2px solid $color5;
    border-radius: 50px;
    background-color: $color5;
    color: #ffffff;
    @include f-size(14);
    @include tracking(50);
    text-align: center;
    transition: 0.3s ease all;
    @include bpSp {
      padding-top: 10px;
      padding-bottom: 10px;
    }
    &:hover {
      text-decoration: none;
      color: $color5;
      background-color: #fff;
    }
  }
  .previouspostslink {}
  .nextpostslink {
    margin-left: 40px;
    @include bpSp {
      margin-left: 20px;
    }
  }
}

.page-navi {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 45px;
  .back {
    display: block;
    max-width: 305px;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 20px;
    border: 2px solid $color5;
    border-radius: 50px;
    background-color: $color5;
    color: #ffffff;
    @include f-size(14);
    @include tracking(50);
    text-align: center;
    transition: 0.3s ease all;

    @include bpSp {
      padding-top: 10px;
      padding-bottom: 10px;
    }

    &:hover {
      text-decoration: none;
      color: $color5;
      background-color: #fff;
    }
  }
}

.post-detail {
  &__title {
    @include f-size(24);
    @include tracking(60);
    font-weight: 500;
  }
  &__date {
    font-family: $Muli;
    @include f-size(13);
    @include tracking(340);
  }

  &__content {
    margin-top: 40px;
    margin-bottom: 70px;
  }

}



/* 汎用クラス */
/* -------------------------------------------------- */
.mp0 {margin: 0px;padding: 0px;}
.p0 {padding:0px !important;}
.m0{margin:0px !important;}

.pT0 {padding-top:0px !important;}
.pT5 {padding-top:5px !important;}
.pT10 {padding-top:10px !important;}
.pT15 {padding-top:15px !important;}
.pT20 {padding-top:20px !important;}
.pT30 {padding-top:30px !important;}
.pT40 {padding-top:40px !important;}
.pT50 {padding-top:50px !important;}
.pT60 {padding-top:60px !important;}

.pB5 {padding-bottom:5px !important;}
.pB10 {padding-bottom:10px !important;}
.pB20 {padding-bottom:20px !important;}
.pB30 {padding-bottom:30px !important;}
.pB40 {padding-bottom:40px !important;}
.pB50 {padding-bottom:50px !important;}
.pB60 {padding-bottom:60px !important;}

.pL5 {padding-left:5px !important;}
.pL10 {padding-left:10px !important;}
.pL20 {padding-left:20px !important;}
.pL30 {padding-left:30px !important;}
.pL40 {padding-left:40px !important;}
.pL50 {padding-left:50px !important;}
.pL60 {padding-left:60px !important;}

.pR0 {padding-right:0px !important;}
.pR10 {padding-right:10px !important;}
.pR20 {padding-right:20px !important;}
.pR30 {padding-right:35px !important;}
.pR40 {padding-right:20px !important;}
.pR50 {padding-right:55px !important;}
.pR60 {padding-right:55px !important;}

.mT0 {margin-top:0px !important;}
.mT5 {margin-top:5px !important;}
.mT10 {margin-top:10px !important;}
.mT15 {margin-top:15px !important;}
.mT20 {margin-top:20px !important;}
.mT25 {margin-top:25px !important;}
.mT30 {margin-top:30px !important;}
.mT40 {margin-top:40px !important;}
.mT50 {margin-top:50px !important;}
.mT60 {margin-top:60px !important;}

.mB0 {margin-bottom:0px !important;}
.mB10 {margin-bottom:10px !important;}
.mB20 {margin-bottom:20px !important;}
.mB25 {margin-bottom:25px !important;}
.mB30 {margin-bottom:30px !important;}
.mB40 {margin-bottom:40px !important;}
.mB50 {margin-bottom:50px !important;}
.mB60 {margin-bottom:60px !important;}

.mL0 {margin-left: 0px !important;}
.mL10 {margin-left: 10px !important;}
.mL15 {margin-left: 15px !important;}
.mL20 {margin-left: 20px !important;}
.mL30 {margin-left: 30px !important;}

.mR0 {margin-right: 0px !important;}
.mR5 {margin-right: 5px !important;}
.mR10 {margin-right: 10px !important;}
.mR20 {margin-right: 20px !important;}
.mR30 {margin-right: 30px !important;}
.mR40 {margin-right: 40px !important;}

.tCenter {text-align:center !important;}
.tLeft {text-align:left !important;}
.tRight {text-align:right !important;}

.fLeft{float:left !important;}
.fRight{float:right !important;}
