.news {
  &-content {}
  &-list {
    &-item {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      & + & {
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1.5px solid $color8;
      }
    }
    &-link {
      @include f-size(13);
      @include tracking(60);
    }
    &-date {
      margin-right: 10px;
      font-family: $Josefin;
      @include tracking(340);
    }
  }
}