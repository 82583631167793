@charset "UTF-8";

/*
#overview
base
*/
/*
#colors
@$color1 #45413b
@$color2 #f0e9d0
@$color3 #e1d3a2
@$color4 #faf6f1
@$color5 #817962
@$color6 #c3b79d
@$color7 #f6eee3
@$color8 #a9a8a5
@$color9 #ececeb
@$color10 #8e7746
@$color11 #919190
@$color12 #898989
@$textColor #000
*/
$color1: #45413b;
$color2: #f0e9d0;
$color3: #e1d3a2;
$color4: #faf6f1;
$color5: #817962;
$color6: #c3b79d;
$color7: #f6eee3;
$color8: #a9a8a5;
$color9: #ececeb;
$color10: #8e7746;
$color11: #919190;
$color12: #898989;
$textColor: #45413b;


// font
$baseFont: 14;
$lineHeight: 2;
$mincho: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", serif;
$Gothic: "Noto Sans JP","ヒラギノ角ゴ ProN W3", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, sans-serif;
$Minion: minion-pro-caption, serif;
$Muli: 'Mulish', sans-serif;
$Play: 'Playfair Display', serif;
$Josefin: 'Josefin Sans', sans-serif;

// コンテンツ幅
$baseWidth: 1000px;
$baseWidthSp: 91.25%;
$baseWidthPadding: 4.375%;

// キャンバスサイズ
$maxWidth: 2000px;

// ブレークポイント
$bpPcs: 1024px;
$bpTb: 768px;
$bpSp: 640px;
$bpSps: 425px;


// z-index
// ↑レイヤー上
// ↓レイヤー下
// eg. z-index: z(child);
$z-index:(
  child,
  nav,
  pulldown,
  header
);
// リストを逆転させる
@function list-reverse($map) {
  $result: ();
  @for $i from length($map)*-1 through -1 {
    $result: append($result, nth($map, abs($i)), comma);
  }
  @return $result;
}
$z-index: list-reverse($z-index);

@function z($name) {
  @return index($z-index, $name);
}
